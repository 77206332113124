import $ from "jquery";

const ready = function() {
  var myWidget = cloudinary.createUploadWidget(
    {
      cloudName: "intuition-design-bureau",
      sources: ["local"],
      styles: {
        palette: {
          window: "#FFF",
          windowBorder: "#90A0B3",
          tabIcon: "#0E2F5A",
          menuIcons: "#5A616A",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link: "#0078FF",
          action: "#FF620C",
          inactiveTabIcon: "#0E2F5A",
          error: "#F44235",
          inProgress: "#0078FF",
          complete: "#20B832",
          sourceBg: "#fff"
        }
      },
      uploadPreset: "arutyunov-info"
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        console.log("Done! Here is the image info: ", result.info);
      }
    }
  );

  if ($("#upload_widget").length) {
    document.getElementById("upload_widget").addEventListener(
      "click",
      function() {
        myWidget.open();
      },
      false
    );
  }

  $(".cloudinary-button").click(function(e) {
    e.preventDefault();
  });
};

$(document).ready(ready);
