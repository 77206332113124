import $, { each } from "jquery";

const ready = function() {
  if ($(".background-container").length) {
    arutyunovInfoScroll();
    $(window).scroll(arutyunovInfoScroll);
    var targetPhotoDelta;
    var realPhotoDelta = 100;

    function arutyunovInfoScroll() {
      targetPhotoDelta =
        (($(document).height() - $(window).height() - $(window).scrollTop()) /
          ($(document).height() - $(window).height())) *
        100;
    }

    function arutyunovInfoParallax() {
      realPhotoDelta += (targetPhotoDelta - realPhotoDelta) / 3;

      $(".background-picture").css(
        "background-position-y",
        100 - realPhotoDelta + "%"
      );
    }

    var cycler = setInterval(arutyunovInfoParallax, 20);
  }
};

$(document).ready(ready);
