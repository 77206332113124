import $, { each } from "jquery";

const ready = function() {
  if ($(".book-animation").length) {
    bookScroll();
    $(window).scroll(bookScroll);
    var targetBookDelta;
    var realBookDelta = 0;

    function bookScroll() {
      let containerTop = $(".book-animation").offset().top - $(window).height();
      targetBookDelta =
        $(window).scrollTop() > containerTop
          ? $(window).scrollTop() - containerTop
          : 0;
    }

    function bookParallax() {
      realBookDelta += (targetBookDelta - realBookDelta) / 2;

      $(".book-3d").css(
        "transform",
        "rotateY(" + (20 + realBookDelta / -40) + "deg) rotateX(" + 0 + "deg)"
      );

      $(".book-3d-scene").css(
        "perspective-origin",
        "center " + (-70 + realBookDelta / 8) + "%"
      );

      console.log(-70 + realBookDelta / 8);
    }

    var cycler = setInterval(bookParallax, 5);
  }
};

$(document).ready(ready);
